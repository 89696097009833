import {
  MODIFY_USER_ADDRESS_REQUEST,
  MODIFY_USER_ADDRESS_SUCCESS,
  MODIFY_USER_ADDRESS_ERROR
} from '~/redux/actions/types'

export const modifyUserAddressRequest = () => ({
  type: MODIFY_USER_ADDRESS_REQUEST
})

export const modifyUserAddressSuccess = payload => ({
  type: MODIFY_USER_ADDRESS_SUCCESS,
  payload
})

export const modifyUserAddressError = error => ({
  type: MODIFY_USER_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const modifyUserAddress =
  (addressId, address, isDefaultAddress = false) =>
  async (dispatch, getState, { api }) => {
    dispatch(modifyUserAddressRequest())
    const { auth } = getState()

    try {
      const response = await api.updateUserAddress(
        auth.token,
        addressId,
        address,
        isDefaultAddress
      )
      dispatch(modifyUserAddressSuccess(response))
    } catch (error) {
      dispatch(modifyUserAddressError(error))
      throw error
    }
  }
