import {
  CREATE_USER_ADDRESS_REQUEST,
  CREATE_USER_ADDRESS_SUCCESS,
  CREATE_USER_ADDRESS_ERROR
} from '~/redux/actions/types'

export const createUserAddressRequest = () => ({
  type: CREATE_USER_ADDRESS_REQUEST
})

export const createUserAddressSuccess = payload => ({
  type: CREATE_USER_ADDRESS_SUCCESS,
  payload
})

export const createUserAddressError = error => ({
  type: CREATE_USER_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const createUserAddress =
  (address, isDefaultAddress = false) =>
  async (dispatch, getState, { api }) => {
    dispatch(createUserAddressRequest())
    const { auth } = getState()

    try {
      const response = await api.createUserAddress(
        auth.token,
        address,
        isDefaultAddress
      )
      dispatch(createUserAddressSuccess(response))
    } catch (error) {
      dispatch(createUserAddressError(error))
      throw error
    }
  }
