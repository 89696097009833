import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useAnalytics, useNewsletterModal, useTranslate } from '~/hooks/actions'
import DynamicInput from '~/components/Common/FormFields/DynamicInput'

import formStyles from './Form.module.scss'
import DynamicPhone from '~/components/Common/FormFields/DynamicPhone'

const NewsletterForm = ({
  auctionNewsletter = false,
  collectSMS = true,
  useModal = true
}) => {
  const translate = useTranslate()
  const { setNewsletterHideModal } = useNewsletterModal()

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      phone: ''
    }
  })

  // Accepts Email Notification
  const hasAgreed = watch('AcceptEmail')
  // Accepts SMS Notification
  const hasAgreedSMS = watch('AcceptSMS')

  // Reset the form
  const resetForm = e => {
    e.preventDefault()
    reset()
    setSubmitting(false)
    setSubmitted(false)
    setError(null)
  }

  const { trackEmailCaptured } = useAnalytics()

  // Handle form submission
  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { email, phone, AcceptSMS } = data
    // Bail out if params are not set
    if (!hasAgreed || !email) setError(true)

    setSubmitting(true)
    setError(null)

    const requestBody = collectSMS
      ? { email, phone: `+${phone}`, smsConsent: AcceptSMS }
      : { email }

    fetch('/api/klaviyo/newsletter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(() => {
        reset()
        setSubmitting(false)
        setError(null)
        setSubmitted(true)
        if (useModal) {
          setNewsletterHideModal()
        }
      })
      .catch(error => {
        setSubmitting(false)
        setSubmitted(false)
        setError(error)
      })

    trackEmailCaptured({
      email: data.email ? data.email : '',
      placement: {
        component: 'newsletter',
        page: '/newsletter'
      }
    })
  }

  return error ? (
    <div className={formStyles.error}>
      <p>
        {error.message ? error.message : translate('newsletter.error_message')}
      </p>
      <button
        className={`cta cta--purple ${formStyles.button}`}
        onClick={e => resetForm(e)}>
        {translate('newsletter.error_button')}
      </button>
    </div>
  ) : submitted ? (
    <div className={formStyles.error}>
      {translate('newsletter.success_message')}
    </div>
  ) : (
    <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
      {/* Email Heading */}
      <div className={formStyles.heading}>
        <h3>
          {auctionNewsletter
            ? translate('newsletter.auction_heading')
            : translate('newsletter.heading')}
        </h3>
      </div>

      {/* Email */}
      <div className="fieldset">
        <div className="field">
          <DynamicInput
            name="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            spellCheck="false"
            autoCapitalize="off"
            placeholder={translate('account.register.email_input_label')}
            register={register}
            errors={errors}
            showErrors={true}
            rules={{
              required: 'Required field.',
              pattern: {
                value:
                  // eslint-disable-next-line
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email address.'
              }
            }}
          />
        </div>
        {auctionNewsletter && (
          <div className={`${formStyles.actions} ${formStyles.actionsAuction}`}>
            <button
              type="submit"
              className={`cta cta--purple ${formStyles.button}`}
              disabled={submitting || !hasAgreed || errors?.email}
              aria-disabled={submitting || !hasAgreed || errors?.email}>
              {submitting
                ? translate('newsletter.submitting')
                : translate('general.submit_button')}
            </button>
          </div>
        )}
      </div>

      {/* Accepts Email */}
      <div className="fieldset">
        <div className="field">
          <div className="input-wrapper">
            <label className="checkbox">
              <input
                type="checkbox"
                name="AcceptEmail"
                {...register('AcceptEmail')}
                className="checkbox__input"
              />
              <span className="checkbox__icon"></span>
              <span className="checkbox__label">
                {auctionNewsletter
                  ? translate('newsletter.auction_consent_label')
                  : translate('newsletter.agreement_checkbox_label')}
              </span>
            </label>
          </div>
        </div>
      </div>
      {collectSMS && (
        <>
          <div className={formStyles.divider}>
            <h3>{translate('newsletter.sms_heading')}</h3>
          </div>
          <div className="fieldset">
            <div className="field">
              <div className="input-wrapper">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="AcceptSMS"
                    {...register('AcceptSMS')}
                    className="checkbox__input"
                  />
                  <span className="checkbox__icon"></span>
                  <span className="checkbox__label">
                    {translate('newsletter.sms_consent_label')}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="field">
              <DynamicPhone
                name="phone"
                control={control}
                errors={errors}
                disabled={submitting || !hasAgreedSMS}
                placeholder="Enter your phone"
                rules={{
                  required: hasAgreedSMS,
                  minLength: {
                    message: 'Invalid number.',
                    value: 8
                  }
                }}
              />
            </div>
          </div>
        </>
      )}

      {!auctionNewsletter && (
        <div className={formStyles.actions}>
          <button
            type="submit"
            className={`cta cta--purple ${formStyles.button}`}
            disabled={submitting || !hasAgreed || errors?.email}
            aria-disabled={submitting || !hasAgreed || errors?.email}>
            {submitting
              ? translate('general.submitting')
              : translate('general.submit_button')}
          </button>
        </div>
      )}
    </form>
  )
}
export default NewsletterForm
