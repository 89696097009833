import Link from 'next/link'
import cx from 'classnames'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useAuth, useTranslate } from '~/hooks/actions'

import formStyles from '~/components/Account/Form.module.scss'
import DynamicInput from '~/components/Common/FormFields/DynamicInput'

const AuthLoginForm = ({ callback, heading }) => {
  const router = useRouter()
  const translate = useTranslate()
  const [error, setError] = useState(null)

  const { login } = useAuth()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  // Reset the form
  const resetForm = e => {
    e.preventDefault()
    reset()
    setError(null)
  }

  // Handle Submit
  const onLogin = async (data, e) => {
    e.preventDefault()
    const { email, password } = data
    setError(null)
    try {
      await login({ email, password })
      if (callback) {
        callback()
      } else {
        if (router.query?.bidder === 'true') {
          router.back()
        } else {
          router.push('/account')
        }
      }
    } catch (error) {
      setError(error)
    }
  }

  return error ? (
    <div className={formStyles.error}>
      <p>
        {translate('account.register.error')}
        <span>
          {error.name === 'OMSError'
            ? error.message
            : translate('account.login.error_message')}
        </span>
        !
      </p>
      <button
        className={cx('cta', 'cta--default', formStyles.button)}
        onClick={e => resetForm(e)}>
        {translate('account.login.error_button')}
      </button>
    </div>
  ) : (
    <form className={formStyles.form} onSubmit={handleSubmit(onLogin)}>
      {/* Heading */}
      <div className={formStyles.heading}>
        <h3>{heading}</h3>
      </div>

      {/* Email */}
      <div className="fieldset">
        <div className="field">
          <DynamicInput
            name="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            spellCheck="false"
            autoCapitalize="off"
            placeholder={translate('account.login.email_input_label')}
            register={register}
            errors={errors}
            showErrors={true}
            rules={{
              required: 'Required field.',
              pattern: {
                value:
                  // eslint-disable-next-line
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email address.'
              }
            }}
          />
        </div>
      </div>

      {/* Password */}
      <div className="fieldset">
        <div className="field">
          <DynamicInput
            type="password"
            name="password"
            autoComplete="current-password"
            placeholder={translate('account.login.password_input_label')}
            register={register}
            errors={errors}
            showErrors={false}
            rules={{
              required: true
            }}
          />
        </div>
      </div>

      {/* Form Actions */}
      <div className={formStyles.actions}>
        <button
          type="submit"
          className="cta cta--purple"
          disabled={error || errors?.email || errors?.password}
          aria-disabled={error || errors?.email || errors?.password}>
          {translate('account.login.submit_button')}
        </button>
        <p>
          {translate('account.login.forgot')}{' '}
          <span>
            <Link href="/forgot">
              <a className={formStyles.link}>
                {translate('account.login.forgot_link')}
              </a>
            </Link>
          </span>
          ?
        </p>
        <p>
          {translate('account.login.new_customer')}{' '}
          <span>
            <Link href="/signup">
              <a className={formStyles.link}>
                {translate('account.login.register_link')}
              </a>
            </Link>
          </span>
          ?
        </p>
      </div>
    </form>
  )
}
export default AuthLoginForm
