import { USER_VERIFIED, VERIFY_USER_SUCCESS, VERIFY_USER_ERROR } from '../types'
import { userAgreeToTermsSuccess } from './agree-to-terms-user'
import {
  checkUserStripePayment,
  checkUserStripeAccount,
  createUserStripeAccount,
  checkUserStripeCusId
} from './check-user-stripe'
import { idCheckUserSuccess } from './idcheck-user'
import { userHasNewsletterSubscription } from './user-has-newsletter-subscription'

export const userVerified = () => ({
  type: USER_VERIFIED
})

export const verifyUserSuccess = payload => ({
  type: VERIFY_USER_SUCCESS,
  payload
})

export const verifyUserError = error => ({
  type: VERIFY_USER_ERROR,
  payload: error,
  error: true
})

// Create a duplicate just for login???
export const verifyUser =
  payload =>
  async (dispatch, getState, { api }) => {
    try {
      // Grab auth token
      const { auth } = getState()
      const response = await api.getUser(auth.token)

      // Query ShopifyAdmin for tag check
      const verification = await fetch(
        `/api/verification/customers/${response.id.split('/').reverse()[0]}`,
        { headers: { 'Cache-Control': 'no-cache' } }
      )

      // Check if the user is already subscribed to marketing
      const newsletter = await fetch('/api/klaviyo/exist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: response.email
        })
      })
      const newsletterResponse = await newsletter.json()
      const hasNewsletterSubscription = newsletterResponse.success
      dispatch(userHasNewsletterSubscription(hasNewsletterSubscription))

      const {
        isIdCheck,
        isVerified,
        hasAgreedToTerms,
        hasStripePayment,
        hasStripeAccount,
        stripeCusId
      } = await verification.json()
      // Dispatch action with status
      dispatch(verifyUserSuccess(isVerified))
      dispatch(idCheckUserSuccess(isIdCheck))
      dispatch(checkUserStripePayment(hasStripePayment))
      dispatch(checkUserStripeAccount(hasStripeAccount))
      dispatch(checkUserStripeCusId(stripeCusId))
      dispatch(userAgreeToTermsSuccess(hasAgreedToTerms))

      // If its from login method and the user doesn't have Stripe Account,
      // Create one for them - FOR OLD USERS
      if (payload && payload.isLogin && !hasStripeAccount) {
        await dispatch(createUserStripeAccount())
      }
    } catch (error) {
      dispatch(verifyUserError(error))
      throw error
    }
  }
